import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import apiClient from "../../_helper/http-common";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import group from "../../Components/assets/Group.jpg";
import "../../Components/assets/style.css";
import { COUNTRIES, States } from "../../countries";
import { TagsInput } from "react-tag-input-component";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import data from "./data";

function Appointment({ isOpen, handleIsOpen }) {
  // const [medicines, setMedicines] = useState([]);
  const [MedArray, setMedArray] = useState([]);
  const [formMeds, setFormMeds] = useState([]);
  //  const [tags, setTags] = React.useState([]);
  // const { isLoading: isLoadingTutorials, refetch: getAllMedicines } = useQuery(
  //   "query-medicines",
  //   async () => {
  //     return await apiClient.get("/medicine/getAllMedicine");
  //   },
  //   {
  //     enabled: false,
  //     onSuccess: (res) => {
  //       if (res?.data?.data?.length > 0) {
  //         const filterValue = _.filter(res?.data?.data, { name: "Other" });
  //         const rejectData = _.reject(
  //           _.orderBy(res.data?.data, ["name"], ["asc"]),
  //           (row) => row?.name === "Other"
  //         );
  //         res["data"]["data"] = [...filterValue, ...rejectData];
  //       }
  //       setMedicines(res.data);
  //     },
  //     onError: (err) => {
  //       console.log("🚀 ~ file: Appointment.js:27 ~ Appointment ~ err", err);
  //     },
  //   }
  // );


  const filterValue = _.filter(data, (row) => row === "Other");
  const rejectData = _.reject(_.orderBy(data, [], ["asc"]), (row) => row === "Other");
  let sortedNames = [...filterValue, ...rejectData];
  // useEffect(() => {
  //   getAllMedicines();
  // }, []);

  const {
    register: appointmentForm,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      fullName: "",
      dob: "",
      number: "",
      city: "",
      country: "US",
      state: "AL",
      streetAddress: "",
      medication: "",
      zipCode: "",
    },
  });

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem == "Other") {
      setIsOther(true);
      // selectedList = selectedList.filter((item) => {
      //   return item == "Other";
      // });
      // setFormMeds([]);
    } else {
      const FIND_ORDER = _.find(selectedList, (row) => row === "Other");
      if (FIND_ORDER) {
        setIsOther(true);
      } else {
        setIsOther(false);
      }
      // selectedList = selectedList.filter((item) => {
      //   return item.name !== "Other";
      // });
      formMeds.push(selectedItem);
      setValue("medication", formMeds);
    }
    setMedArray(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    // const index = formMeds?.findIndex((med) => med === removedItem);
    // console.log(removedItem, "removedItem >>>>>>>>>>>>>>>>>>>>>>");
    // console.log("index,", index);
    // console.log("formMeds.splice(index, 1)", formMeds.splice(index, 1));
    // if (index > -1) {
    //   setFormMeds(formMeds.splice(index, 1));
    // }
    const Filter_values = _.filter(MedArray, (row) => row !== removedItem);
    const rejectItems = _.reject(formMeds, (row) => row === removedItem);
    if (removedItem === "Other") {
      setIsOther(false);
    }
    setMedArray(Filter_values);
    setFormMeds(rejectItems);
    setValue("medication", rejectItems);
  };

  const otherMed = (tag) => {
    setFormMeds(tag);
    setValue("medication", tag);
  };

  const navigate = useNavigate();

  const onSubmit = async (data) => {

    const {
      fullName: name,
      city,
      dob,
      number: phone_number,
      streetAddress,
      zipCode: zip_code,
      medication,
      state,
      country,
      medications_help,
    } = data;
    try {
      const docRef = await addDoc(collection(db, "users"), {
        name,
        city,
        dob,
        streetAddress,
        state,
        country,
        phone_number,
        zip_code,
        medication: medications_help
          ? [...medication, medications_help]
          : medication,
        timestamp: new Date(),
      });

      // console.log("Document written with ID: ", docRef.id);

      handleIsOpen(false);
      reset({
        fullName: "",
        dob: "",
        number: "",
        city: "",
        country: "",
        state: "",

        streetAddress: "",
        medication: "",
        zipCode: "",
      });
      navigate("/thankyou");

      // window.location.href = "https://worldcaremd.navigohealth.com/";
    } catch (e) {
      swal({
        icon: "error",
        title: "Oops",
        text: "server error",
        timer: 1000,
        buttons: false,
      });

      console.error("Error adding document: ", e);
    }
  };
  const [takeMedication, setTakeMedication] = useState("no");
  const [isOther, setIsOther] = useState(false);
  const onChangeTakeMedication = (e) => {
    setTakeMedication(e.target.value);
  };

  return (
    <ReactModal
      preventScroll={true}
      onRequestClose={handleIsOpen}
      isOpen={isOpen}
      className={"popupClass"}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm appoint-img">
            <img className="img-fluid" src={group} alt="" />
          </div>
          <div className="col-sm appoint-fields">
            <h4>Welcome to WorldCareMD</h4>
            <p>Please fill out your information below.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="fullName" className="form-label">
                  Full Name*
                </label>
                <input
                  {...appointmentForm("fullName", { required: true })}
                  className="form-control"
                />
                {errors.fullName && (
                  <span className="invalid-feedback">FullName is required</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  Date of Birth*
                </label>
                <input
                  {...appointmentForm("dob", { required: true })}
                  type="date"
                  className="form-control"
                />
                {errors.dob && (
                  <span className="invalid-feedback">
                    {" "}
                    Date of Birth is required
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  Address*
                </label>
                <input
                  {...appointmentForm("streetAddress", { required: true })}
                  className="form-control"
                />
                {errors.streetAddress && (
                  <span className="invalid-feedback">Address is required</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dob" className="form-label">
                  City*
                </label>
                <input
                  {...appointmentForm("city", { required: true })}
                  className="form-control"
                />
                {errors.city && (
                  <span className="invalid-feedback">City is required</span>
                )}
              </div>
              <div className="d-flex">
                <div className="form-group">
                  <label htmlFor="test-state" className="form-label">
                    State*
                  </label>
                  <Select
                    {...appointmentForm("state", { required: true })}
                    onChange={(e) => setValue("state", e.code)}
                    options={States}
                    defaultValue={{ name: "Alabama", code: "AL" }}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                  />
                  {errors.state && (
                    <span className="invalid-feedback">State is required</span>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="dob" className="form-label">
                    Country*
                  </label>
                  <Select
                    {...appointmentForm("country", { required: true })}
                    onChange={(e) => setValue("country", e.code)}
                    options={COUNTRIES}
                    defaultValue={{ name: "United States", code: "US" }}
                    isDisabled
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                  />
                  {errors.country && (
                    <span className="invalid-feedback">
                      Country is required
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group">
                  <label htmlFor="dob" className="form-label">
                    Mobile/Phone No. *
                  </label>
                  <input
                    {...appointmentForm("number", { required: true })}
                    type="tel"
                    className="form-control"
                  />
                  {errors.number && (
                    <span className="invalid-feedback">
                      Mobile/Phone No. is required
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="dob" className="form-label">
                    Zip / postcode *
                  </label>
                  <input
                    {...appointmentForm("zipCode", { required: true })}
                    type="text"
                    className="form-control"
                  />
                  {errors.zipCode && (
                    <span className="invalid-feedback">
                      Zip / postcode. is required
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group mt-0">
                <label htmlFor="takeMedication" className="form-label">
                  Are you taking any medication?
                </label>
                <div>
                  <input
                    type="radio"
                    value="yes"
                    checked={takeMedication === "yes"}
                    onChange={onChangeTakeMedication}
                    name="takeMedication"
                  />
                  Yes &nbsp; &nbsp; &nbsp;
                  <input
                    type="radio"
                    value="no"
                    checked={takeMedication === "no"}
                    onChange={onChangeTakeMedication}
                    name="takeMedication"
                  />
                  No
                </div>
              </div>
              {takeMedication === "yes" && (
                <>
                  <label htmlFor="medication" className="form-label">
                    Prescriptions*
                  </label>
                  <Multiselect
                    options={sortedNames}
                    onSelect={onSelect}
                    //   displayValue="name"
                    showCheckbox={true}
                    onRemove={onRemove}
                    selectedValues={MedArray}
                    isObject={false}
                  />
                  <div className="form-group">
                    {/* <Select
                      onChange={(e) =>
                        e.name === "Other" ? setIsOther(true) : setValue("medication", e.name)
                      }
                      options={[{ name: "Other" }, ...medicines.data]}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.name}
                    /> */}

                    {errors.address && (
                      <span className="invalid-feedback">
                        Prescriptions is required
                      </span>
                    )}
                  </div>
                  {isOther && (
                    <>
                      <label htmlFor="medication" className="form-label">
                        Please enter other medication*
                      </label>
                      {/* <input {...appointmentForm("medication")} className="form-control" /> */}
                      {/* <TagsInput value={formMeds} onChange={otherMed} name="test" placeHolder="press enter to add new" /> */}
                      <input
                        {...appointmentForm("medications_help")}
                        className="form-control"
                      />
                    </>
                  )}
                </>
              )}
              <div className="modal-buttons mt-2">
                <button onClick={handleIsOpen} className="btn btn-x">
                  Close
                </button>
                <button
                  className="btn btn-submit"
                  type="submit"
                  disabled={!isDirty || !isValid}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Appointment;
