import React from "react";
import { PrivacyPolicyStyleCss } from "./PrivacyPolicy.style";

const Privacypolicy = () => {
  return (
    <PrivacyPolicyStyleCss>
      <div>
        <div className="privacy">
          <div className="container-fluid p-0 ">
            <div className="policy-heading">
              <h2>Worldcare MDPrivacy Policy</h2>
              <p>Effective Date:January 1st, 2023</p>
            </div>
          </div>
          <div className="container  pb-5">
            <div className="row justify-content-center ">
              <div className="contentClass">
                <h2>Applicability</h2>

                <p>
                  This Policy describes ourinformation collection and use
                  practiceson the sites where it appears. This Policy does not
                  describe our practices on siteswhere it does not appear. It
                  does not apply to the practices of subsidiaries or affiliates.
                  It also does not apply to information about our members or our
                  employees.
                </p>
                <h2>Information We Collect</h2>
                <p>
                  <b>Contact information.</b> We collect your name and email
                  address. This also includes your company and job title. We may
                  collect your address and phone number.
                </p>
                <p>
                  <b>Demographic information.</b>
                  We may collect information about your location. For example,
                  your state or zip code.
                </p>
                <p>
                  <b>Usage information.</b>
                  We collectlogs and session data when you visit our site. We
                  collect browser and operating system information. We may
                  collect what site you came from or what site you visit when
                  you leave us. We also look at the areas of our site you visit
                  and any actions you make. We collect your IP address.We also
                  collect device identifiers.This might include your hardware
                  model.
                </p>
                <h2>Business Purposes for CollectingInformation</h2>
                <p>
                  <b>We use information to communicate with you.</b>
                  We use information to respond to your questions. This includes
                  questions about our site or services. For example, we might
                  use your information to respond to your feedback. We also use
                  information to communicate with you about our policies and
                  terms or about your relationship with us.
                </p>
                <p>
                  <b>We use information to provide and improve our services.</b>
                  We use information to provide our services. This includes
                  customer support services. We may use information to improve
                  our site or services or to customize your experience with us.
                </p>
                <p>
                  <b>We use information for marketing purposes.</b>
                  We may use information to let you know about our services.For
                  example, this could be by phone, email, or on social media
                  platforms. We also use information to send you newsletters or
                  other content we think you may find interesting. We may send
                  you surveys.
                </p>
                <p>
                  <b>We use information to protect our company and members.</b>
                  We use information to protect our company. We use information
                  to identify fraud and secure our systems and accounts.
                </p>
                <p>
                  <b>
                    We use information for other purposes as permitted by law or
                    as we may disclose to you.
                  </b>
                </p>
                <h2>Methods of Collecting Information</h2>
                <p>
                  <b>We collect information directly from you. </b> We collect
                  information if you contact us. We also collect information
                  from you if sign up for email communications, or otherwise
                  submit it to us. This could be in person. It may also be over
                  the phone, or online.
                </p>
                <p>
                  <b>We collect information passively.</b> We use tracking tools
                  to collect information. Tracking tools include browser cookies
                  and web beacons. We may do this on our websites and in emails
                  that we send to you. We collect information about users over
                  time when you use our websites. We have third parties collect
                  information this way.
                </p>
                <p>
                  <b>We collect information about you from others.</b>
                  .For example, our business partners may provide information
                  about you to us.
                </p>
                <h2>We Combine Information</h2>
                <p>
                  We combine informationwe collect from you on the website with
                  information we receive from you offline. We combine
                  information that we have collected across other third party
                  sites. We combine information across devices, such as
                  computers and mobile devices. We also combine information you
                  provide with information we obtain from third parties.
                </p>
                <h2>We Share Information with Others</h2>
                <p>
                  <b>
                    We may share information within our family of companies.{" "}
                  </b>
                  We may share information with our affiliates, parent company,
                  and other related companies. This includes current and future
                  companies.
                </p>
                <p>
                  <b>
                    We share information with vendors who perform services on
                    our behalf.
                  </b>
                  We may shareinformation with trusted vendors that help us
                  deliver our services. For example, wemay share information
                  with vendors who help us host and operate the site or help us
                  send emails.
                </p>
                <p>
                  <b>
                    We share information if we think we have to in order to
                    comply with the law or to protect ourselves.
                  </b>
                  We share information we collect about you to respond to a
                  court order or subpoena. We share information in response to a
                  government agency or investigatory body request. We share
                  information we collect when we investigate potential fraud.
                </p>
                <p>
                  <b>
                    We share information with any successor to all or part of
                    our business.
                  </b>
                  If all or part of our business was sold, we may share your
                  information as part of that transaction. If there is a merger
                  or acquisition, we may also share your information. If there
                  is a financing or bankruptcy, we may share your information.
                </p>
                <p>
                  <b>
                    We share information as permitted by law and for other
                    reasons we may describe to you.
                  </b>
                </p>
                <h2>You Have Certain Choices</h2>
                <p>
                  <b>You can opt out of receiving our marketing emails.</b> To
                  stop receiving our promotional emails, follow the
                  “unsubscribe” instructions in any promotional message you get
                  from us. Even if you opt out of getting marketing messages, we
                  will still send you transactional messages. These include
                  responses to your questions or other information about your
                  account.
                </p>
                <p>
                  <b>You can control cookies and tracking tools.</b>
                  Your browser may give you the ability to control cookies or
                  other tracking tools. How you do so depends on the type of
                  tool. Certain browsers can be set to reject browser cookies.
                  To control flash cookies, which we may use on certain websites
                  from time to time, you can go here. Why? Because flash cookies
                  cannot be controlled through your browser settings.
                </p>
                <p>
                  <b>
                    Our Do Not Track Policy: Some browsers have “do not track”
                    features that allow you to tell a website not to track you.
                    These features are not all uniform. We do not currently
                    respond to those signals.
                  </b>
                  If you block cookies, certain features on our sites may not
                  work. If you block or reject cookies, not all of the tracking
                  described here will stop. Options you select are browser and
                  device specific.
                </p>
                <p>
                  <b>California residents’ rights.</b> If you are a California
                  resident you may have additional rights. These rights exist
                  under the California Consumer Privacy Act (CCPA). If you
                  reside in California and are not a job applicant, employee or
                  employee of another company interacting with us in your job
                  role, you have the right to ask us what information we collect
                  and use. You also have the right to ask what information we
                  disclose and sell. You also have the right to request that we
                  delete information we have collected from you. To exercise
                  your rights contact us as described at the end of this policy.
                  We will verify your request by matching information you
                  provide with information we already have about you. We will
                  not discriminate against you because you have exercised any of
                  your rights under CCPA. If you are submitting on someone
                  else’s behalf we may ask for additional information to verify
                  your identity. This may include providing a signed letter
                  verifying your right to make this request. We are also
                  required to tell Californians if we “sell” information as that
                  term is defined by applicable law. Sell means to share
                  personal information with a third party for monetary or other
                  valuable consideration. We do not do this based on our
                  understanding of that term. We do not have actual knowledge
                  that we sell personal information of minors under the age of
                  16. Certain exceptions may apply to requests you might make.
                  For example, CCPA does not apply to Medical information
                  governed by the Confidentiality of Medical Information Act or
                  protected health information that is collected by a covered
                  entity or business associate subject to the Health Insurance
                  Portability and Accountability Act.
                </p>
                <p>
                  <b>Nevada residents’ rights .</b>
                  We have disclosure obligations in Nevada. There, we do not
                  exchange Nevada residents’ personal information for money.
                  Nevada residents may opt out of the future sale of their
                  information to a third party by contacting us as described at
                  the end of this policy.
                </p>
                <h2>We Use StandardSecurity Measures</h2>
                <p>
                  The Internet isnot 100% secure. We cannot promise that your
                  use of our sites or apps will be completely safe. We encourage
                  you to use caution when using the Internet. This includes not
                  sharing your passwords.
                </p>
                <h2>We Store Information in the United States</h2>
                <p>
                  Information we collect is stored in the United States. The
                  site is intended for people who are in the United States.If
                  you live outside of the United States and choose to use this
                  site you understand that it is at your own risk. If you live
                  outside of the US, you understand and agree that we may
                  transfer your information to the US. Our sites and businesses
                  are subject to US laws, which may not afford the same level of
                  protection as those in your country. By submitting your
                  information, you agree to the processing of it in the US.
                </p>
                <h2>Third Party Sites and Links</h2>
                <p>
                  We may link to third-party sites or apps. If you click on a
                  link to a third party site, you will be taken to websites we
                  do not control. This includes our pages on social media sites.
                  This policy does not apply to the privacy practices of these
                  websites. Read the privacy policy of other websites carefully.
                  We are not responsible for these third party practices.
                </p>
                <h2>Information Collection from Children</h2>
                <p>
                  Our sites are meant for adults. We do not knowingly collect
                  personally identifiable information from children under 13
                  without permission from a parent or guardian. If you are a
                  parent or legal guardian and think your child under 13 has
                  given us information, you can email us. You can also write to
                  us at the address listed at the end of this policy. Please
                  mark your inquiries “COPPA Information Request.” Parents, you
                  can learn more about how to protect children’s privacy on-line{" "}
                  <a href="#">here</a>.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have questions about this Policy, you can email us at
                  support@worldcaremd.com.
                </p>
                <h2>Policy Updates</h2>
                <p>
                  We may make changes or updates to this Policy. We will notify
                  you of any material changes to this Policy as required by law.
                  All changes will also be posted on our website. Please check
                  our site periodically for updates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrivacyPolicyStyleCss>
  );
};

export default Privacypolicy;
