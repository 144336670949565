import React, { useState, useEffect } from "react";
import logo from "../../Components/assets/logo.png";
import Appointment from "../Home/Appointment";
import { NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const toggle = () => {
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  };

  const [scrollActive, setScrollActive] = useState(false);
  const [isOpen, setIsOpen] = useState();

  const handleIsOpen = (value) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  const handleClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleAboutClick = () => {
    navigate("/about");
    window.scrollTo(0, 0);
    toggle();
  };

  return (
    <>
      <Appointment handleIsOpen={handleIsOpen} isOpen={isOpen} />
      <nav
        className={
          "navbar navbar-expand-lg transition-all " +
          (scrollActive
            ? "fixed-top bg-white shadow mt-lg-0"
            : "fixed-top mt-lg-2")
        }
      >
        <div className="container">
          <NavLink className="navbar-brand" to="/" onClick={handleClick}>
            <img src={logo} className="logo-img" alt="" />
          </NavLink>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars text-white"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#services"
                  onClick={() => {
                    navigate("/");
                    toggle();
                  }}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={handleAboutClick}
                  //  activeClassName="active"
                  to="/about"
                >
                  About
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={toggle}
                  //  activeClassName="active"
                  to="/privacy"
                >
                  Prescription Policy
                </NavLink>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("/");
                    toggle();
                  }}
                  href="#contact"
                >
                  Contact
                </a>
              </li>
            </ul>
            <div className="d-flex items-center gap-4">
              <div className="header-btn">
                <a
                  href="javascript:;"
                  onClick={handleIsOpen}
                  className="btn start-btn appointment-btn"
                >
                  Get Started
                </a>
              </div>
              <div className="header-btn">
                <a
                  className="btn login-btn"
                  href="https://worldcaremd.navigohealth.com/"
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
