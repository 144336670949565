import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  EffectCreative,
  Autoplay,
  EffectFade,
} from "swiper";

import arrowdwnmb from "../../Components/assets/arrow-mb.png";
import arrowdwn from "../../Components/assets/arrow-down.png";
import arrow from "../../Components/assets/arrow.png";
import banner2mask from "../../Components/assets/b-pattern.png";
import banner2maskBackground from "../../Components/assets/group-doct.png";
import bluecircle from "../../Components/assets/bluecircle.png";
import gradientblur from "../../Components/assets/gradientblur.png";
import greenblur from "../../Components/assets/greenblur.png";
import petImage from "../../Components/assets/petImage.png";
import patternLeft from "../../Components/assets/pattern-left.png";
import banner3 from "../../Components/assets/banner3.png";
import register from "../../Components/assets/register.svg";
import check from "../../Components/assets/check.svg";
import request from "../../Components/assets/request.svg";
import talk from "../../Components/assets/talk.svg";
import allergies from "../../Components/assets/allergies.svg";
import cold from "../../Components/assets/cold.svg";
import pharyngitis from "../../Components/assets/pharyngitis.svg";
import infection from "../../Components/assets/skin-infection.svg";
import gastroenteritis from "../../Components/assets/gastroenteritis.svg";
import ear from "../../Components/assets/ear-infection.svg";
import pink from "../../Components/assets/pink-eye.svg";
import respiratory from "../../Components/assets/respiratory-Infections.svg";
import sinusitis from "../../Components/assets/sinusitis.svg";
import sprain from "../../Components/assets/sprain-and-strains.svg";
import urinary from "../../Components/assets/urinary-tract-infection.svg";
import consulting from "../../Components/assets/consulting-for-International.svg";
import depression from "../../Components/assets/Depression&Anxiety.svg";
import work from "../../Components/assets/Work-related-stress.svg";
import alcohol from "../../Components/assets/Alcohol-Drug-problems.svg";
import grief from "../../Components/assets/Grief&loss.svg";
import eating from "../../Components/assets/Eating-Disorders.svg";
import stress from "../../Components/assets/Stress&Pain-management.svg";
import marriage from "../../Components/assets/Marriage-relationship-problems.svg";
import personal from "../../Components/assets/Personal-Distress.svg";
import Eldercare from "../../Components/assets/Eldercare-childcare-issues.svg";
import major from "../../Components/assets/Major-life-event.svg";
import concerns from "../../Components/assets/Health-care- concerns.svg";
import much from "../../Components/assets/MUCH MORE.svg";
import phone from "../../Components/assets/phone-left.png";
import mail from "../../Components/assets/phone-right.png";
import image from "../../Components/assets/GroupBanner.png";
import Appointment from "./Appointment";
import Contact from "./Contact";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/effect-creative";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isOpen, setIsOpen] = useState();
  const navigate = useNavigate();

  const handleIsOpen = (value) => {
    setIsOpen(!isOpen);
  };
  const HEALTH_SECTION = [
    {
      mainHeading: "General Health",
      insideData: [
        { image: allergies, title: "Allergies" },
        { image: cold, title: "Cold & Flu" },
        { image: pharyngitis, title: "Pharyngitis" },
        { image: infection, title: "Skin Infections" },
        { image: gastroenteritis, title: "Gastroenteritis" },
        { image: ear, title: "Ear Infection" },
        { image: pink, title: "Pink Eye" },
        { image: respiratory, title: "Respiratory Infections" },
        { image: sinusitis, title: "Sinusitis" },
        { image: sprain, title: "Sprains and Strains" },
        { image: urinary, title: "Urinary tract Infection" },
        { image: consulting, title: "Consulting for International" },
      ],
    },
    {
      mainHeading: "Behavioral Health",
      insideData: [
        { image: depression, title: "Depression" },
        { image: work, title: "Grief and Loss" },
        { image: alcohol, title: "Drug/Alcohol Issues" },
        { image: grief, title: "Marriage Issues" },
        { image: eating, title: "Eating Disorders" },
        { image: stress, title: "Smoking Cessation" },
        { image: marriage, title: "Parenting Issues" },
        { image: personal, title: "Anxiety" },
        { image: Eldercare, title: "Childcare, Eldercare" },
        { image: major, title: "Obsessive Compulsive Disorder" },
        { image: concerns, title: "ADHD" },
        { image: much, title: "Gambling Addiction" },
      ],
    },
  ];

  const data = [
    "97% Member Satisfaction",
    "92% Of Medical Issues Resolved",
    "98% Client Retention",
    "<15 Min Response Time",
    "98% Of Members Recommend ",
    "Top quality, board-certified doctors",
    "Get peace of mind",
    "Prescriptions sent to your local pharmacy",
  ];

  const firstFive = data.slice(0, 5);
  const lastThree = data.slice(5);
  return (
    <div>
      <Appointment handleIsOpen={handleIsOpen} isOpen={isOpen} />
      <section className="first-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner1-content">
                <h1>
                  Take charge of your health now with
                  <span className="blue-text">WorldCareMD</span>
                </h1>
                <p className="paraheader">
                  Healthcare reimagined for
                  <br />
                  Unions and other employeers
                </p>
                <div className="dots_group">
                  <span className="dots"></span>
                  <span className="dots"></span>
                </div>
                <div className="banner1-btn">
                  <button
                    onClick={handleIsOpen}
                    className="btn appointment-btn btn-green"
                  >
                    Get started today
                  </button>
                  {/* <a href="#" className="works-btn icon-btn">
                    How it Works
                    <img className="ms-2" src={arrow} alt="" />
                  </a> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 mt-5 pt-5 px-5">
            <div className="col-lg-6">
              <Swiper
                // effect={"creative"}
                effect="Fade"
                speed={2000}
                slidesPerView={1}
                pagination={true}
                // modules={[EffectFade,Navigation, Pagination, EffectCreative, Autoplay]}
                modules={[EffectFade, Navigation, Pagination, Autoplay]}
                creativeEffect={{
                  prev: {
                    // shadow: true,
                    translate: [0, 0, -400],
                  },
                  next: {
                    translate: ["100%", 0, 0],
                  },
                }}
                loop={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                //   onSlideChange={() => console.log("slide change")}
                //   onSwiper={(swiper) => console.log(swiper)}
              >
                {[
                  { image: banner1, title: "Gastroenterioiogy" },
                  {
                    image: require("../../Components/assets/Allergist-2.png"),
                    title: "Allergist",
                  },
                  {
                    image: require("../../Components/assets/Dermatologist-3.png"),
                    title: "Psychiatrist",
                  },
                  {
                    image: require("../../Components/assets/Psychiatrist-4.png"),
                    title: "Dermatologist",
                  },
                ]?.map(({ image, title }, index) => (
                  <SwiperSlide
                    style={{ height: "564px" }}
                    key={"image" + index}
                  >
                    <img src={image} className="w-100" alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> */}
            <div className="col-lg-6">
              <img src={image} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="second-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner2-image">
                <img src={banner2maskBackground} className="w-100" alt="" />
                <img src={banner2mask} className="w-100 b-pattern" alt="" />
                <div className="gr-blur">
                  <img src={greenblur} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner2-content">
                <h2>
                  <span className="text-green">About </span>WorldCareMD
                </h2>
                <p>
                  WorldCareMD member's benefit from one of the country's largest
                  networks of telemedicine physicians.
                </p>
                <p>
                  Over the phone or via secure bi-directional video and email,
                  our national network of board certified, licensed Internal
                  Medicine and Emergency Room physicians diagnose ailments,
                  recommend therapy, and prescribe drugs for its members.
                </p>
                {/* <a href="#!" className="btn read-more-btn">
                  Read More
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="third-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner3-content">
                  <h2>
                    <span className="text-green">Why </span>WorldCare MD
                  </h2>
                  <div className="wrapper-flex d-flex flex-wrap justify-content-between">
                    <div className="rightDiv">
                      {firstFive.map((row, index) => (
                        <div className="company-info1" key={"first" + index}>
                          <div className="info">
                            <img className="me-3" src={check} alt="" />
                            <span>{row}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="leftDiv">
                      {lastThree.map((row, index) => (
                        <div className="company-info1" key={"last" + index}>
                          <div className="info">
                            <img className="me-3" src={check} alt="" />
                            <span>{row}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="blue-wraper">
                    <img src={bluecircle} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-wraper">
          <img src={gradientblur} alt="" />
        </div>
        <div className="arrow-container">
          <img src={arrowdwn} alt="" className="desktop-arrow" />
          <img src={arrowdwnmb} alt="" className="mb-arrow" />
        </div>
      </section>

      <section className="fourth">
        <div className="container">
          <h2 className="text-center">
            How to Get <span className="text-green">Started</span>
          </h2>
          <div className="start-map-content">
            <div className="row">
              {_.map(
                [
                  {
                    image: register,
                    section: "register",
                    title: "Register",
                    content: `Setup your profile once you're invited and complete your electronic health record, then login to your account anytime 24/7 to request a consult.`,
                  },
                  {
                    image: request,
                    section: "request",
                    title: "Request a Consult",
                    content: `Consult with one of our board-certified physicians via secure bi-directional video or phone day or night.`,
                  },
                  {
                    image: talk,
                    section: "talk",
                    title: "Talk with Doctor",
                    content: `Receive diagnosis and treatment by our physicians to get quality care and peace of mind where ever you are day or night.`,
                  },
                ],
                ({ image, section, title, content }, index) => (
                  <div className="col-lg-4" key={section}>
                    <div className={`${section} text-center mt-lg-0`}>
                      <img src={image} alt="" />
                    </div>
                    <div className={`${section}-content`}>
                      <h3 className="text-center">
                        {index + 1}. {title}
                      </h3>
                      <p className="text-center">{content}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <hr className="" />
      <section className="fifth" id="services">
        <div className="container">
          <h2 className="text-center">
            We Treat a Broad Range of <br />
            <span className="text-green">Non-Emergency Medical</span> and{" "}
            <span className="text-green">
              Behavioral
              <br />
              Health Conditions
            </span>
          </h2>
          {_.map(HEALTH_SECTION, ({ mainHeading, insideData }, index) => (
            <div key={mainHeading} className="Behavioral-health">
              <p
                className={`health text-center text-lg-start ${
                  index === 1 ? "mt-5" : ""
                }`}
              >
                {mainHeading}
              </p>
              <div className="health-problems">
                <div className="row">
                  {_.map(insideData, ({ image, title }) => (
                    <div className="box-cstm">
                      <div className="healthbox-border" key={title}>
                        <div className="health-box">
                          <img src={image} className="me-3" alt="" />
                          <span>{title}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <hr className="" />
      <section className="petSection position-relative" id="pet">
        <div className="container">
          <div className="row">
            <div className="available-data">
              <h2>
                High-quality <span className="text-green">vet care</span>
                 available 24 hours. 
                <span className="text-green">Pet prescriptions</span> &  
                <span className="text-green">treatments</span> delivered to you.
              </h2>
              <p>
                Virtual Veterinary Care bridges the gap with convenient,
                high-quality veterinary telemedicine to meet your pet's needs
                when in-clinic care isn't necessary.
              </p>
              <button
                onClick={() => {
                  window.open("https://worldcare.teletails.com/", "_blank");
                }}
                className="btn btn-green"
              >
                Get Pet Care
              </button>
            </div>
            <div className="img-fluid">
              <img src={petImage} alt="" />
            </div>
          </div>
        </div>
        <div className="blue-img position-absolute">
          <img src={bluecircle} alt="" className="w-100" />
        </div>
        <div className="pattern-left position-absolute">
          <img src={patternLeft} alt="" className="w-100" />
        </div>
      </section>
      <section className="form" id="contact">
        <div className="container">
          <h2 className="text-center">Contact Us</h2>
          <div className="justify-content-center contact-form">
            <div className="row">
              <div className="col-md-6">
                <div className="chat-box">
                  <img src={phone} alt="" />
                  <div>
                    <p>Monday-Sunday: 24/7</p>
                    <p>(888) 919-6753</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="chat-box">
                  <img src={mail} alt="" />
                  <div>
                    <p>We will reply to you as early as possible:</p>
                    <p>
                      <a className="text-white" href="#">
                        support@worldcaremd.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Contact />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
